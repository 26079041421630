<template>
  <div class="ui email">
    <header class="bb">
      <h2 class="inner-title m-b-0 header">Email address</h2>
    </header>
    <div class="content align-form">
      <template v-if="!isShowChangeEmailMsg">
        <DynamicForm
          class="out-form"
          v-model="changeEmailForm"
          :submit-promise="submitPromise"
          submit-text="save"
          @df-submit="submitChangeEmail"
        />
      </template>
      <p class="h3 text" v-else>
        Change Email successful. We will send you verification message.
        <br />Please check your email.
      </p>
    </div>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import DynamicForm from '@/components/forms/DynamicForm'
import changeEmailForm from './email.form'
import { updateUserEmail } from '@/api/user'

export default {
  name: 'ChangeEmail',
  components: {
    DynamicForm
  },
  data() {
    return {
      changeEmailForm: deepcopy(changeEmailForm),
      submitPromise: null,
      isShowChangeEmailMsg: false
    }
  },
  methods: {
    submitChangeEmail(formData) {
      const { email } = formData.data
      this.submitPromise = updateUserEmail({ email }).then(res => {
        this.isShowChangeEmailMsg = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.personal-info-form {
  /deep/ {
    .form-group {
      margin-bottom: 15px;
    }
  }
}

.ui.email {
  .header {
    margin: 0rem 2rem;
    font-weight: 400;
    @media only screen and (max-width: 770px) {
      margin: 1rem;
    }
  }

  .content {
    margin: 1rem 2rem;
    @media only screen and (max-width: 770px) {
      margin: 1rem;
    }
  }
}
</style>
