export default {
  name: 'changeEmailForm',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'Email address',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'email',
            label: 'New Email',
            placeholder: '',
            rules: 'required|email',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'confirmEmail',
            veeAs: 'confirm email',
            label: 'Confirm New Email',
            placeholder: '',
            rules: {
              required: true,
              confirmed: 'email'
            },
            selected: ''
          }
        ]
      ]
    }
  ]
}
